<template>
    <div>
        <!-- <Header /> -->
        <section class="podcasts">
            <!-- Container -->
            <div class="container">
                <!-- Row -->
                <div class="row justify-content-center align-items-center">
                    <!-- Col -->
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <img src="../../assets/image/podcast_cover.jpg" class="img-fluid podcast_image mb-5 rounded-5"
                            alt="404 not found">
                        <h1 class="mb-5">სულ მალე...</h1>
                        <router-link to="/" class="btn-motivation">მენიუში დაბრუნება</router-link>
                    </div>
                    <!-- End Col -->
                </div>
                <!-- End Row -->
            </div>
            <!-- End Container -->
        </section>
        <!-- <Footer /> -->
    </div>
</template>

<script>
// import Header from '@/components/Header.vue'
// import Footer from '@/components/NewFooter.vue'

export default {
    name: "404",
    components: {
        // Header,
        // Footer
    }
}
</script>

<style scoped>
.footer {
    margin-top: 106px;
}

.podcasts {
    margin-top: 50px;
}

.podcasts h1 {
    font-family: var(--Third-Font);
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;

    font-feature-settings: 'case' on;

    color: var(--Second-Color);

    margin-top: 16px;
    margin-bottom: 44px;
}

.podcasts .btn-motivation {
    max-width: 368px;
}

@media screen and (max-width: 767px) {
    .footer {
        margin-top: 205px;
    }

    .podcasts {
        margin-top: 89px;
    }

    .podcasts h1 {
        font-size: 20px;
        line-height: 24px;
    }

    .podcasts .btn-motivation {
        font-size: 16px;
        font-weight: 400;
        max-width: 368px;
    }

    .podcast_image {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .podcast_image {
        width: 37%;
    }
}
</style>