<template>
    <section class="hero">
        <!-- <h1>KENCHADZE.Network</h1>
        <p>თქვენი გზა წარმატებული კარიერისკენ!</p> -->
        <img src="../../../assets/image/network.jpg" alt="logo">
    </section>

    <div class="container">
        <p class="mb-5">KENCHADZE.Network არის დასაქმების სააგენტო, რომელიც აერთიანებს პროფესიონალებსა და დამწყებ
            ტალანტებს. ჩვენი
            მისიაა, გავხდეთ თქვენი პირადი დასაქმების აგენტები და დაგეხმაროთ უკეთესი სამსახურის პოვნაში - სრულიად უფასოდ!
        </p>

        <h2 class="text-center fs-4 fw-bold">როგორ მუშაობს KENCHADZE.Network?</h2>
        <div class="steps">
            <div class="step-card">
                <div class="step-number">1</div>
                <h3 class="fs-5 mb-3">აირჩიეთ ვაკანსია</h3>
                <p>აირჩიეთ თქვენთვის საინტერესო ვაკანსია</p>
            </div>
            <div class="step-card">
                <div class="step-number">2</div>
                <h3 class="fs-5 mb-3">გამოგზავნეთ CV</h3>
                <p>გამოგზავნეთ თქვენი რეზიუმე ჩვენთან</p>
            </div>
            <div class="step-card">
                <div class="step-number">3</div>
                <h3 class="fs-5 mb-3">გასაუბრება</h3>
                <p>ჩვენი HR სპეციალისტები განიხილავენ თქვენს კანდიდატურას და დაგინიშნავენ გასაუბრებას</p>
            </div>
            <div class="step-card">
                <div class="step-number">4</div>
                <h3 class="fs-5 mb-3">გახდით წევრი</h3>
                <p>გახდით KENCHADZE.Network-ის წევრი და მიიღეთ წვდომა ექსკლუზიურ შესაძლებლობებზე</p>
            </div>
        </div>
    </div>

    <section class="benefits">
        <div class="container">
            <h2 class="text-center mb-5 fs-4 fw-bold">KENCHADZE.Network-ის უპირატესობები</h2>
            <ul class="benefits-list">
                <li>პერსონალიზებული მიდგომა თქვენი კარიერული მიზნების მიმართ</li>
                <li>პირდაპირი კავშირი წამყვან კომპანიებთან</li>
                <li>შესაძლებლობა, მიიღოთ რეკომენდაცია საუკეთესო ვაკანსიებზე</li>
                <li>კარიერული ზრდის მუდმივი მხარდაჭერა</li>
            </ul>

            <div class="text-center">
                <a href="https://network.dk.ge/VacancyList?id=286&type=c_c" class="cta-button">
                    შემოგვიერთდი
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
}
</script>

<style scoped>
/* body {
    font-family: system-ui, -apple-system, sans-serif;
    margin: 0;
    padding: 0;
    color: var(--navy) !important;
    line-height: 1.6 !important;
} */
.hero {
    background-color: var(--navy) !important;
    color: white !important;
    padding: 0 2rem !important;
    text-align: center !important;
    margin-bottom: 40px;
}

.hero img {
    max-width: 300px;
    width: 100%;
}

.hero h1 {
    color: var(--gold) !important;
    margin-bottom: 3rem !important;
    font-size: 2.5rem !important;
}

.container {
    max-width: 1200px !important;
    margin: 0 auto !important;
    padding: 2rem !important;
}

.steps {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
    gap: 2rem !important;
    margin: 3rem 0 !important;
}

.step-card {
    background: white !important;
    border-radius: 10px !important;
    padding: 2rem !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    border-top: 4px solid var(--gold) !important;
}

.step-number {
    background: var(--gold) !important;
    color: var(--navy) !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-weight: bold !important;
    margin-bottom: 1rem !important;
}

.benefits {
    background: #f5f5f5 !important;
    padding: 3rem 0 !important;
}

.benefits-list {
    list-style: none !important;
    padding: 0 !important;
}

.benefits-list li {
    margin-bottom: 1rem !important;
    padding-left: 2rem !important;
    position: relative !important;
}

.benefits-list li:before {
    content: "✓" !important;
    color: var(--gold) !important;
    position: absolute !important;
    left: 0 !important;
    font-weight: bold !important;
}

.cta-button {
    display: inline-block !important;
    background: var(--gold) !important;
    color: var(--navy) !important;
    padding: 1rem 2rem !important;
    border-radius: 30px !important;
    text-decoration: none !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    transition: transform 0.2s !important;
    margin-top: 2rem !important;
}

.cta-button:hover {
    transform: translateY(-2px) !important;
}

.text-center {
    text-align: center !important;
}
</style>
